import React from 'react';

const Logo = () => (
  <svg viewBox="0 0 70 90" enableBackground="new 0 0 70 90">
    <title>Agência LAVA</title>
    <g id="logo-lava" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="type3-copy-4" transform="translate(15.000000, 5.000000)">
        <polygon
          id="Path-4"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FF0000"
          points="55,85 45,85 30,55 25,65 20,55 24.9,45 34.9,45"
        />
        <polygon
          id="Path-4-Copy-9"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FF0000"
          points="55,35 45,35 30,5 25,15 20,5 24.9,-5 34.9,-5"
        />
        <polygon
          id="Path-4-Copy"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FF0000"
          points="15,85 5,85 -15,45 -5,45"
        />
        <polygon
          id="Path-2"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FF0000"
          points="-15,-5 -15,35 15,35 10,25 -5,25 -5,-5"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
